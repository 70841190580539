import React, { SyntheticEvent, useCallback, useMemo, useState } from 'react'

import { useSelector } from 'react-redux'
import { ReduxStoreState } from '../../../../../../../store'

import { TextField } from '../../../../components/TextField'
import { NumberField } from '../../../../components/NumberField'
import numeral from 'numeral'

const cloneDeep = require('lodash.clonedeep')

type Props = {
    values: any
    form: any
    allowEdit: boolean
}

export const Waterfall = (props: Props) => {
    const { values, form, allowEdit } = props
    const model = useSelector((state: ReduxStoreState) => state.lender.originationModel)

    const totalEquity = useMemo(() => {
        return model?.sources?.totalEquity
    }, [model])

    return (
        <>
            <div className="cash-flow-tab-wrapper">
                <div className={'debt-assumption-table-wrapper left-half'}>
                    <div className="block-title">
                        <div>Equity Share</div>
                    </div>
                    <div className="custom-table-title row">
                        <div className="col-3"></div>
                        <div className="col-2 text-right"></div>
                        <div className="col-2 text-right">Amount</div>
                    </div>
                    <div className={'custom-table-wrapper'}>
                        {values.equity_share?.map((item, ind) => {
                            return (
                                <React.Fragment key={ind.toString()}>
                                    <div className="custom-table-row row">
                                        <div className="col-3 ">
                                            <TextField
                                                name={`equity_share[${ind}].title`}
                                                readOnly={item.title == 'LP'}
                                            />
                                        </div>
                                        <div className="col-2 text-right">
                                            <NumberField
                                                name={`equity_share[${ind}].percentage`}
                                                numberFormat={'percent'}
                                            />
                                        </div>

                                        <div className="col-2 text-right">
                                            <NumberField
                                                name={`equity_share[${ind}].amount`}
                                                numberFormat={'currency'}
                                                readOnly={true}
                                            />
                                        </div>
                                        {allowEdit && item.title != 'LP' && (
                                            <div
                                                className="delete-col"
                                                onClick={(e: SyntheticEvent) => {
                                                    e.preventDefault()
                                                    e.stopPropagation()
                                                    form.mutators.removeEquityShare(ind)
                                                }}
                                            >
                                                <img src={'/_img/lenderDashboard/trash.svg'} alt="trash" />
                                            </div>
                                        )}
                                    </div>
                                </React.Fragment>
                            )
                        })}

                        <div className="custom-table-summary row">
                            <div className="col-3">Total</div>
                            <div className="col-2 text-right">
                                {numeral(values?.computed?.totalESPct).format('0.[00]%')}
                            </div>
                            <div className="col-2 text-right">
                                {numeral(values?.computed?.totalESAmnt).format('$0,0')}
                            </div>
                        </div>
                        {allowEdit && (
                            <div className="add-row">
                                <a
                                    href="#"
                                    className="add-row-button"
                                    onClick={(e: SyntheticEvent) => {
                                        e.preventDefault()
                                        e.stopPropagation()
                                        form.mutators.addEquitySahre()
                                    }}
                                >
                                    <div className="add-row-content">
                                        <span className="add-row-label">Add Equity Share</span>
                                        <svg
                                            width="14"
                                            height="14"
                                            viewBox="0 0 14 14"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M12.657 6.40711C13.0712 6.4071 13.407 6.74289 13.407 7.1571C13.407 7.57132 13.0712 7.9071 12.657 7.9071L7.74939 7.9071V12.814C7.74939 13.2282 7.4136 13.564 6.99939 13.564C6.58518 13.564 6.24939 13.2282 6.24939 12.814L6.24939 7.9071L1.3437 7.9071C0.929491 7.90711 0.593704 7.57132 0.593704 7.1571C0.593704 6.74289 0.929491 6.4071 1.3437 6.4071L6.24939 6.4071L6.24939 1.50072C6.24939 1.08651 6.58518 0.750724 6.99939 0.750724C7.4136 0.750724 7.74939 1.08651 7.74939 1.50072L7.74939 6.4071L12.657 6.40711Z"
                                                fill="#4486FF"
                                            />
                                        </svg>
                                    </div>
                                </a>
                            </div>
                        )}
                    </div>
                </div>

                <div className={'debt-assumption-table-wrapper right-half'}>
                    <div className="block-title">
                        <div>Waterfall Structure</div>
                    </div>
                    <div className="custom-table-title row">
                        <div className="col-3"></div>
                        {values.equity_share?.map((item, ind) => {
                            return (
                                <React.Fragment key={ind.toString()}>
                                    <div className="col-1 text-right">{item.title}</div>
                                </React.Fragment>
                            )
                        })}
                        <div className="col-2 text-right">IRR</div>
                        <div className="col-2 text-right"></div>
                    </div>

                    <div className={'custom-table-wrapper'}>
                        {values.hurdles?.map((item, ind) => {
                            return (
                                <React.Fragment key={ind.toString()}>
                                    <div className="custom-table-row row">
                                        <div className="col-3">
                                            <TextField name={`hurdles[${ind}].title`} />
                                        </div>
                                        {values.hurdles[ind].ratios?.map((item1, ind1) => {
                                            return (
                                                <React.Fragment key={ind1.toString()}>
                                                    <div className="col-1 text-right">
                                                        <NumberField
                                                            name={`hurdles[${ind}].ratios[${ind1}]`}
                                                            numberFormat={'percent'}
                                                        />
                                                    </div>
                                                </React.Fragment>
                                            )
                                        })}
                                        <div className="col-2 text-right">
                                            <NumberField name={`hurdles[${ind}].irr`} numberFormat={'percent'} />
                                        </div>
                                        {allowEdit && (
                                            <div
                                                className="delete-col "
                                                onClick={(e: SyntheticEvent) => {
                                                    e.preventDefault()
                                                    e.stopPropagation()
                                                    form.mutators.removeHurdle(ind)
                                                }}
                                            >
                                                <img src={'/_img/lenderDashboard/trash.svg'} alt="trash" />
                                            </div>
                                        )}
                                    </div>
                                </React.Fragment>
                            )
                        })}
                        {allowEdit && (
                            <div className="add-row">
                                <a
                                    href="#"
                                    className="add-row-button"
                                    onClick={(e: SyntheticEvent) => {
                                        e.preventDefault()
                                        e.stopPropagation()
                                        form.mutators.addHurdle()
                                    }}
                                >
                                    <div className="add-row-content">
                                        <span className="add-row-label">Add Hurdle</span>
                                        <svg
                                            width="14"
                                            height="14"
                                            viewBox="0 0 14 14"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M12.657 6.40711C13.0712 6.4071 13.407 6.74289 13.407 7.1571C13.407 7.57132 13.0712 7.9071 12.657 7.9071L7.74939 7.9071V12.814C7.74939 13.2282 7.4136 13.564 6.99939 13.564C6.58518 13.564 6.24939 13.2282 6.24939 12.814L6.24939 7.9071L1.3437 7.9071C0.929491 7.90711 0.593704 7.57132 0.593704 7.1571C0.593704 6.74289 0.929491 6.4071 1.3437 6.4071L6.24939 6.4071L6.24939 1.50072C6.24939 1.08651 6.58518 0.750724 6.99939 0.750724C7.4136 0.750724 7.74939 1.08651 7.74939 1.50072L7.74939 6.4071L12.657 6.40711Z"
                                                fill="#4486FF"
                                            />
                                        </svg>
                                    </div>
                                </a>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            {/*language=scss*/}

            <style jsx>{`
                /* Pattern styles */
                @import './src/scss/colors.scss';
                .block-title {
                    border-bottom: 1px solid #dde4e9;
                    padding: 0 20px 7px;
                    > div {
                        font-weight: 500;
                        font-size: 15px;
                        color: #161c26;
                    }
                }
                .delete-col {
                    cursor: pointer;
                }
                .add-row {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    margin-top: 10px;
                    padding-left: 20px;

                    .add-row-button {
                        display: flex;
                        align-items: center;
                        transition: transform 0.25s cubic-bezier(0.165, 0.84, 0.44, 1);

                        .add-row-content {
                            border: 1px solid #4486ff;
                            box-sizing: border-box;
                            border-radius: 4px;
                            padding: 10px 12px;
                            line-height: 14px;
                            color: #4486ff;
                            font-size: 13px;
                            font-weight: 500;

                            .add-row-label {
                                max-width: 100px;
                                overflow: hidden;
                                white-space: nowrap;
                                transition: 0.25s ease-out;
                                height: 24px;
                                font-weight: 400;
                            }
                        }

                        svg {
                            height: 100%;
                            width: auto;
                            vertical-align: top;
                            padding-left: 6px;
                        }
                        &:hover {
                            .add-row-label {
                                max-width: 80px;
                            }
                            svg {
                                opacity: 1;
                            }
                            .add-row-content {
                                color: #4486ff;
                            }
                        }
                    }
                }
                .row {
                    margin-bottom: 10px;
                }

                .cash-flow-tab-wrapper {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    grid-gap: 24px;
                    .custom-table-title {
                        margin-top: 20px;
                        div {
                            font-size: 12px;
                            line-height: 15px;
                            letter-spacing: 0.5px;
                            text-transform: uppercase;
                            color: #676f7a;
                        }
                    }
                    .custom-table-wrapper {
                        overflow: hidden;
                        .custom-table-row {
                            font-size: 15px;
                            padding: 5px 0 5px 20px;
                            align-items: center;
                            margin-bottom: 0;
                            &:nth-of-type(2n-1) {
                                background-color: #f9fafb;
                            }
                        }
                        .custom-table-summary {
                            font-size: 15px;
                            padding: 5px 0 5px 20px;
                            align-items: center;
                            margin-bottom: 0;
                            background: #eceff2;
                        }
                    }
                    .bold-500 {
                        font-weight: 900;
                    }
                    .debt-assumption-table-wrapper {
                        border: 1px solid $border;
                        background-color: $white;
                        margin-bottom: 20px;
                        padding: 12px 0 20px 0;
                        min-height: 250px;
                        .debt-assumption-title {
                            padding: 14px 22px;
                            font-size: 15px;
                            font-weight: 500;
                            color: $black;
                            border-bottom: 1px solid $border;
                        }
                    }
                    .widget-title {
                        padding: 12px 20px;
                        border: 1px solid $border;
                        border-bottom: 0;
                        background: $white;
                        font-size: 15px;
                        font-weight: 500;
                        color: $black;
                    }
                    .widget-tabs {
                        display: flex;
                        background-color: $white;
                        padding: 10px;
                        border-left: 1px solid $border;
                        border-right: 1px solid $border;
                        border-top: 1px solid $border;
                        .widget-tab {
                            font-size: 15px;
                            margin-right: 32px;
                            font-weight: 500;
                            cursor: pointer;
                            &.active {
                                color: $blue;
                                position: relative;
                                &:after {
                                    content: '';
                                    position: absolute;
                                    width: 100%;
                                    height: 3px;
                                    bottom: -3px;
                                    left: 0;
                                    border-top: 3px solid #4485ff;
                                    border-top-right-radius: 15px;
                                    border-top-left-radius: 15px;
                                }
                            }
                        }
                    }
                    .kpi-block {
                        display: flex;
                        background-color: $white;
                        padding: 20px 10px;
                        border-left: 1px solid $border;
                        border-right: 1px solid $border;
                        .kpi-item {
                            margin-right: 40px;
                            &__title {
                                font-weight: normal;
                                font-size: 14px;
                                color: $gray;
                                margin-bottom: 5px;
                            }
                            &__value {
                                font-weight: 500;
                                font-size: 22px;
                                color: $black;
                            }
                        }
                    }
                }
            `}</style>
        </>
    )
}
