import React, { useMemo } from 'react'
import { LenderDashboardChart } from '../../LenderDashboardChart'
import { useSelector } from 'react-redux'
import { ReduxStoreState } from '../../../../../../../store'

type Props = {
    title?: string
    fcfInternal?: any
}

export const PromoteTabChart = (props: Props) => {
    const { title, fcfInternal } = props
    const cashFlow = useSelector((state: ReduxStoreState) => state.lender.originationModel.cashFlow)

    const series = useMemo(() => {
        const defaultSeries = [
            {
                name: title,
                data: [],
            },
        ]

        fcfInternal.map((item, ind) => {
            defaultSeries[0].data.push(Math.round(item))
        })

        return defaultSeries
    }, [cashFlow])

    return (
        <LenderDashboardChart
            series={series}
            xaxisTitle={'Years'}
            yaxisTitle={'Amount'}
            enableCustomCategories={true}
        />
    )
}
