import React, { SyntheticEvent, useCallback, useMemo, useState } from 'react'

import { useSelector } from 'react-redux'
import { ReduxHelper, ReduxStoreState } from '../../../../../../../store'

import { Field, Form } from 'react-final-form'

import createDecorator from 'final-form-calculate'

import numeral from 'numeral'
import { Waterfall } from './Waterfall'
import { CashFlowTable } from './CashFlowTable'
import { Hurdle } from './Hurdle'
import { Kpi } from './Kpi'
import { calcEquityMultiple, IRRCalc, sumArr } from './Utils'
import { calcHurdleArrays, calcHurdleTotals } from 'origination-model/promote'
const cloneDeep = require('lodash.clonedeep')

function removeItemFromArray(array: Array<any>, index: number = array.length) {
    return array.slice(0, index).concat(array.slice(index + 1, array.length))
}

type Props = {
    isDashboard: boolean
}

export const PromotePage = (props: Props) => {
    const TEST_MODE = false
    const fieldName = 'promote'

    const { isDashboard } = props

    const model = useSelector((state: ReduxStoreState) => state.lender.originationModel)

    const handleSubmit = useCallback(async (values) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { unitMix, computed, cashFlow, ...originationModel } = values
    }, [])

    const totalEquity = useMemo(() => {
        if (TEST_MODE) return 2500000
        else return model?.sources?.totalEquity
    }, [model])

    const FCF = useMemo(() => {
        if (TEST_MODE) return [175000, 180250, 185658, 191227, 4446964]
        let fcf = []
        const noi = model.cashFlow.noi.years
        let rr: number[] = []
        let debt: number[] = []
        let debtMez: number[] = []
        let capex: number[] = []
        let assetManagementFeeGP: number[] = []
        let proceedsFromSale: number[] = []
        let saleCost: number[] = []
        let loanPayOff: number[] = []
        // console.log('model.cashFlow', model.cashFlow)
        model.cashFlow.other.map((item, ind) => {
            if (item.id == 'replacementReserves') rr = item.years
            else if (item.id == 'debtServiceInterest') debt = item.years
            else if (item.id == 'debtServiceInterestMez') debtMez = item.years
            else if (item.id == 'capex') capex = item.years
            else if (item.id == 'assetManagementFeeGP') assetManagementFeeGP = item.years
            else if (item.id == 'proceedsFromSale') proceedsFromSale = item.years
            else if (item.id == 'saleCost') saleCost = item.years
            else if (item.id == 'loanPayOff') loanPayOff = item.years
        })
        for (let i = 0; i < noi.length; i++) {
            fcf.push(
                Math.round(noi[i]) +
                    Math.round(rr[i] || 0) +
                    Math.round(debt[i] || 0) +
                    Math.round(debtMez[i] || 0) +
                    Math.round(capex[i] || 0) +
                    Math.round(assetManagementFeeGP[i] || 0) +
                    Math.round(proceedsFromSale[i] || 0) +
                    Math.round(saleCost[i] || 0) +
                    Math.round(loanPayOff[i] || 0),
            )
        }

        return fcf
    }, [model])

    const hurdles = useMemo(() => {
        let hurdles = []

        model?.promote?.hurdles?.map((mh, mhidx) => {
            let fcf = [-totalEquity].concat(FCF)
            let title = ''
            let priorDistribution = null
            let cfRemaining = null
            if (mhidx == 0) {
                title = 'Preferred Return and Return of Capital'
            } else {
                priorDistribution = new Array(model.promote.equity_share.length)
                for (let i = 0; i < priorDistribution.length; i++) {
                    priorDistribution[i] = new Array(hurdles[mhidx - 1].arrays.distributionArr[0].length)
                    for (let j = 0; j < priorDistribution[i].length; j++) {
                        priorDistribution[i][j] = 0
                    }
                }
                let x = mhidx - 1
                while (x >= 0 && x >= mhidx - 2) {
                    for (let i = 0; i < priorDistribution.length; i++) {
                        for (let j = 0; j < priorDistribution[i].length; j++) {
                            priorDistribution[i][j] += hurdles[x].arrays.distributionArr[i][j]
                        }
                    }
                    x--
                }

                cfRemaining = hurdles[mhidx - 1].totals.remainingFCF
            }

            let hurdleArrays = calcHurdleArrays(
                model.promote.equity_share,
                mh.ratios,
                fcf,
                priorDistribution,
                cfRemaining,
                mh.irr,
            )

            let hurdleTotals = calcHurdleTotals(hurdleArrays, fcf, cfRemaining)

            hurdles[mhidx] = {
                irrMax: mh.irr,
                title: title,
                arrays: hurdleArrays,
                totals: hurdleTotals,
            }
        })
        return hurdles
    }, [model, FCF, totalEquity])

    const showOnOM = model?.promote?.showOnOM ? true : false

    const shareHolderFlows = useMemo(() => {
        let shareHolderFlows = []

        model?.promote?.equity_share?.map((esItem, esIdx) => {
            let distributions = []
            let contributions = []

            for (let j = -1; j < FCF.length; j++) {
                distributions.push(0)
                contributions.push(0)
            }
            for (let i = 0; i < hurdles.length; i++) {
                for (let j = 0; j < hurdles[i].arrays.distributionArr[esIdx].length; j++) {
                    distributions[j] += hurdles[i].arrays.distributionArr[esIdx][j]
                }
            }
            for (let j = 0; j < hurdles[0].arrays.contributionArr[esIdx].length; j++) {
                contributions[j] += hurdles[0].arrays.contributionArr[esIdx][j]
            }

            shareHolderFlows.push({ title: esItem.title, distributions: distributions, contributions: contributions })
        })
        shareHolderFlows = shareHolderFlows.reverse()
        //console.log('shareHolderFlows', shareHolderFlows)
        return shareHolderFlows
    }, [model, hurdles])

    const promotemutator = useMemo(() => {
        return {
            removeHurdle(params, state, form) {
                //console.log('removeHurdle', params)

                form.changeValue(state, `hurdles`, (list) => {
                    return removeItemFromArray(list, params[0])
                })
            },

            addHurdle(params, state, form) {
                // console.log('addHurdle', params)

                form.changeValue(state, `hurdles`, (list) => {
                    //console.log('addHurdle', list.length, list)
                    list.push({
                        title: '',
                        ratios: [0.0, 0.0],
                    })
                    return list
                })
            },

            removeEquityShare(params, state, form) {
                //console.log('removeEquityShare', params)

                form.changeValue(state, `hurdles`, (list) => {
                    //console.log('removeEquityShare hurdles', list)
                    list.map((item, ind) => {
                        list[ind].ratios = removeItemFromArray(list[ind].ratios, params[0])
                    })
                    return list
                })

                form.changeValue(state, `equity_share`, (list) => {
                    return removeItemFromArray(list, params[0])
                })
            },
            addEquitySahre(params, state, form) {
                // console.log('addEquitySahre', params)

                form.changeValue(state, `equity_share`, (list) => {
                    //console.log('addEquitySahre', list.length, list)
                    list.push({
                        title: '',
                        percentage: 0.0,
                    })
                    return list
                })
                form.changeValue(state, `hurdles`, (list) => {
                    list.map((item, ind) => {
                        item.ratios.push(0.0)
                    })
                    return list
                })
            },
        }
    }, [])

    const promotedecorator = useMemo(() => {
        return [
            createDecorator({
                field: /^equity_share/, // when a deeper field matching this pattern changes...
                updates: (value, name, allValues: any) => {
                    const out: any = {}
                    if (name.indexOf('percentage') == -1) return out

                    // console.log('equity_share.percentage model', model)
                    // console.log('equity_share.percentage ', name, value)
                    //console.log('equity_share.percentage allValues', allValues)

                    let totalPct = 0
                    let totalAmnt = 0

                    const listname = name.substr(0, name.indexOf('['))
                    const idx = parseInt(name.substr(name.indexOf('[') + 1, name.indexOf(']') - name.indexOf('[') - 1))

                    //console.log('trying', allValues[listname].length, idx)
                    if (idx < allValues[listname].length) {
                        //  console.log('change all values amount', allValues[listname], idx)
                        allValues[listname][idx].amount = (numeral(value).value() * totalEquity).toFixed(4)
                    }
                    if (allValues?.equity_share) {
                        allValues?.equity_share.map((item, ind) => {
                            //if (!fld.match(/type$|percentage$/)) {
                            // console.log(item.abs)
                            totalPct += numeral(item.percentage).value()
                            totalAmnt += numeral(item.percentage).value() * totalEquity
                            //}
                        })
                    }
                    out['computed.totalESPct'] = totalPct
                    out['computed.totalESAmnt'] = totalAmnt

                    return out
                },
            }),
        ]
    }, [])

    return (
        <div>
            <Form
                onSubmit={handleSubmit}
                mutators={promotemutator}
                decorators={promotedecorator}
                initialValues={useMemo(() => {
                    return cloneDeep(model.promote)
                }, [])}
                validate={useCallback((v) => {
                    //console.log('validate v', v)
                    //console.log('validate v.promote', v[fieldName])
                    //console.log('validate model.promote', model[fieldName])
                    let out = {}
                    if (v['equity_share']) {
                        out['equity_share'] = cloneDeep(v['equity_share'])

                        out['equity_share'].map((item, ind) => {
                            delete item.amount
                        })
                    }

                    if (v['hurdles']) out['hurdles'] = cloneDeep(v['hurdles'])
                    out['showOnOM'] = v['showOnOM'] ? true : false

                    if (v['equity_share'] && v['hurdles'])
                        ReduxHelper.setIn(['lender', 'originationModel', fieldName], out)
                    return null
                }, [])}
                render={({ handleSubmit, form, submitting, pristine, values, submitError }) => {
                    //console.log('render values', values)

                    return (
                        <>
                            {isDashboard && (
                                <div>
                                    <Field
                                        name={'showOnOM'}
                                        style={{ marginLeft: '10px' }}
                                        component={'input'}
                                        id={'showOnOM'}
                                        type="checkbox"
                                        initialValue={showOnOM}
                                    />
                                    <label htmlFor="showOnOM" style={{ color: 'grey', marginLeft: '10px' }}>
                                        Show Promote on OM?
                                    </label>
                                </div>
                            )}
                            <div className="cash-flow-tab-wrapper">
                                <div className={'debt-assumption-table-wrapper'}>
                                    <div className="custom-table-title row">
                                        <div className="col-10 bold-500">Equity Waterfall</div>
                                    </div>

                                    <div className="custom-table-title row">
                                        <div className="col-10">
                                            The equity will be connected to the "Equity" in the Sources tab in the
                                            Operating Assumptions
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <Waterfall values={values} form={form} allowEdit={isDashboard} />

                            <div className="custom-table-row row"></div>

                            <div style={{ display: 'inline-block' }}>
                                {shareHolderFlows.map((item, ind) => {
                                    let fcfinternal: number[] = []
                                    for (let i = 0; i < item.distributions.length; i++) {
                                        fcfinternal.push(item.distributions[i] - item.contributions[i])
                                    }
                                    const emkpi = calcEquityMultiple(fcfinternal, -fcfinternal[0])
                                    const irrkpi = IRRCalc(fcfinternal, 0.001)
                                    return (
                                        <React.Fragment key={ind.toString() + 'a'}>
                                            {(isDashboard || item.title == 'LP') && (
                                                <React.Fragment key={ind.toString()}>
                                                    <Kpi
                                                        title={item.title + ' Cash Flow IRR'}
                                                        kpi={numeral(irrkpi).format('0.[00]%')}
                                                    />
                                                    <Kpi
                                                        title={item.title + ' Cash Flow Equity Multiple'}
                                                        kpi={numeral(emkpi).format('0.[00]') + 'x'}
                                                    />
                                                </React.Fragment>
                                            )}
                                        </React.Fragment>
                                    )
                                })}
                            </div>
                            <div className="custom-table-row row"></div>

                            {shareHolderFlows.map((item, ind) => {
                                return (
                                    <React.Fragment key={ind.toString() + 'b'}>
                                        {(isDashboard || item.title == 'LP') && (
                                            <React.Fragment key={ind.toString()}>
                                                <div className="custom-table-row row"></div>
                                                <CashFlowTable
                                                    title={item.title + ' Cash Flow'}
                                                    distributions={item.distributions}
                                                    contributions={item.contributions}
                                                />
                                            </React.Fragment>
                                        )}
                                    </React.Fragment>
                                )
                            })}

                            <div className="custom-table-row row"></div>
                            {isDashboard && (
                                <CashFlowTable title={'Property Cash Flow'} fcf={[-totalEquity].concat(FCF)} />
                            )}

                            {isDashboard &&
                                hurdles.map((hurdle, hurdleIdx) => {
                                    return (
                                        <React.Fragment key={hurdleIdx.toString()}>
                                            <Hurdle
                                                id={hurdleIdx + 1}
                                                hurdle={hurdle}
                                                shareholders={values.equity_share}
                                                shareholdersDist={values.hurdles[hurdleIdx].ratios}
                                                isDashboard={isDashboard}
                                            />
                                        </React.Fragment>
                                    )
                                })}
                        </>
                    )
                }}
            />

            {/*language=scss*/}

            <style jsx>{`
                /* Pattern styles */
                @import './src/scss/colors.scss';
                .delete-col {
                    cursor: pointer;
                }
                .left-half {
                    float: left;
                    width: 50%;
                    min-height: 250px;
                }

                .right-half {
                    float: left;
                    width: 50%;
                    min-height: 250px;
                }
                .add-row {
                    position: relative;
                    left: 80%;
                }
                .row {
                    margin-bottom: 10px;
                }
                .add-row-button {
                    display: flex;
                    align-items: center;
                    right: -12px;
                    top: -20px;
                    color: #fff;
                    font-size: 13px;
                    font-weight: 700;
                    text-decoration: none;
                    padding-left: 20px;
                    padding-top: 8px;
                    transform: translateX(5px);
                    transition: transform 0.25s cubic-bezier(0.165, 0.84, 0.44, 1);
                    svg {
                        height: 100%;
                        width: auto;
                        vertical-align: top;
                        transition: opacity 0.2s;
                        opacity: 0.85;
                    }
                    &:hover {
                        .add-row-label {
                            max-width: 80px;
                        }
                        svg {
                            opacity: 1;
                        }
                        .add-row-content {
                            background-color: rgba(68, 134, 255, 1);
                        }
                    }
                }
                .add-row-content {
                    background-color: rgba(68, 134, 255, 0.8);
                    padding-right: 6px;
                    border-top-right-radius: 2px;
                    border-bottom-right-radius: 2px;
                    line-height: 24px;
                }
                .add-row-label {
                    max-width: 100px;
                    overflow: hidden;
                    white-space: nowrap;
                    transition: 0.25s ease-out;
                    height: 24px;
                    padding-left: 4px;
                    font-weight: 400;
                }

                .cash-flow-tab-wrapper {
                    .custom-table-wrapper {
                        .custom-table-title {
                            text-transform: uppercase;
                            font-size: 12px;
                            color: #676f7a;
                            padding: 7px;
                        }
                        .custom-table-row {
                            font-size: 14px;
                            padding: 7px;
                            align-items: center;
                            &:nth-of-type(2n) {
                                background-color: $row-background-2;
                            }
                        }
                    }
                    .bold-500 {
                        font-weight: 900;
                    }
                    .debt-assumption-table-wrapper {
                        border: 1px solid $border;
                        background-color: $white;
                        margin-bottom: 20px;
                        padding: 12px 20px;
                        .debt-assumption-title {
                            padding: 14px 22px;
                            font-size: 15px;
                            font-weight: 500;
                            color: $black;
                            border-bottom: 1px solid $border;
                        }
                    }
                    .widget-title {
                        padding: 12px 20px;
                        border: 1px solid $border;
                        border-bottom: 0;
                        background: $white;
                        font-size: 15px;
                        font-weight: 500;
                        color: $black;
                    }
                    .widget-tabs {
                        display: flex;
                        background-color: $white;
                        padding: 10px;
                        border-left: 1px solid $border;
                        border-right: 1px solid $border;
                        border-top: 1px solid $border;
                        .widget-tab {
                            font-size: 15px;
                            margin-right: 32px;
                            font-weight: 500;
                            cursor: pointer;
                            &.active {
                                color: $blue;
                                position: relative;
                                &:after {
                                    content: '';
                                    position: absolute;
                                    width: 100%;
                                    height: 3px;
                                    bottom: -3px;
                                    left: 0;
                                    border-top: 3px solid #4485ff;
                                    border-top-right-radius: 15px;
                                    border-top-left-radius: 15px;
                                }
                            }
                        }
                    }
                    .kpi-block {
                        display: flex;
                        background-color: $white;
                        padding: 20px 10px;
                        border-left: 1px solid $border;
                        border-right: 1px solid $border;
                        .kpi-item {
                            margin-right: 40px;
                            &__title {
                                font-weight: normal;
                                font-size: 14px;
                                color: $gray;
                                margin-bottom: 5px;
                            }
                            &__value {
                                font-weight: 500;
                                font-size: 22px;
                                color: $black;
                            }
                        }
                    }
                }
            `}</style>
        </div>
    )
}
