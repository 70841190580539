import React, { FunctionComponent, useEffect, useState } from 'react'
import numeral from 'numeral'
import dynamic from 'next/dist/next-server/lib/dynamic'

import { CHART_COLOR } from '../../../../../utils/constants/chartColors'
import { ApexOptions } from 'apexcharts'

const Chart = dynamic(() => import('react-apexcharts'), {
    ssr: false,
})

type Props = {
    series: Array<{ name: string; data: number[] }>
    title?: string
    xaxisTitle?: string
    yaxisTitle?: string
    enableCustomCategories?: boolean
}

export const LenderDashboardChart: FunctionComponent<Props> = (props: Props) => {
    const { title, series, xaxisTitle, yaxisTitle, enableCustomCategories } = props

    const customCategories = Array.from(Array(series[0]['data'].length).keys())
    const options = {
        series,
        options: {
            chart: {
                height: 350,
                type: 'line',
                toolbar: {
                    show: false,
                },
                background: '#fff',
            },
            colors: CHART_COLOR.LENDER_DASHBOARD_PALETTE,
            dataLabels: {
                enabled: false,
            },
            legend: {
                show: true,
                position: 'top',
                horizontalAlign: 'left',
                labels: {
                    colors: ['#000'],
                },
            },
            stroke: {
                width: 2,
            },
            tooltip: {
                theme: 'light',
                x: {
                    formatter: (x) => (enableCustomCategories ? `${xaxisTitle} ${x - 1}` : `${xaxisTitle} ${x}`),
                },
            },
            title: {
                text: title || '',
                align: 'center',
                style: {
                    color: '#000',
                },
            },
            grid: {
                row: {
                    colors: ['transparent'], // takes an array which will be repeated on columns
                    opacity: 0.5,
                },
            },
            xaxis: {
                title: {
                    text: xaxisTitle || '',
                    style: {
                        fontWeight: 400,
                    },
                },
                labels: {
                    formatter: (val) => val,
                },
                categories: enableCustomCategories ? customCategories : null,
            },
            yaxis: {
                title: {
                    text: yaxisTitle || '',
                    style: {
                        fontWeight: 400,
                    },
                },
                labels: {
                    formatter: (val) => numeral(val).format('$0,0'),
                },
            },
        },
    }

    const [chartWidth, setChartWidth] = useState(200)
    useEffect(() => {
        const chartWrapper = document.getElementById('chart-wrapper')
        setChartWidth(chartWrapper?.offsetWidth - 20)
    }, [])

    return (
        <div className={'chart-wrapper'} id={'chart-wrapper'}>
            <Chart
                options={options.options as ApexOptions}
                series={options.series}
                type="line"
                height={350}
                width={chartWidth}
            />
            {/*language=scss*/}
            <style jsx>{`
                @import './src/scss/colors.scss';
                .chart-wrapper {
                    background-color: $white;
                    border: 1px solid $border;
                    width: 100%;
                    .chart-title {
                        padding: 12px 20px;
                        border-bottom: 1px solid $border;
                        font-size: 15px;
                        font-weight: 500;
                        color: $black;
                    }
                }
            `}</style>
        </div>
    )
}
