import React, { SyntheticEvent, useCallback, useMemo } from 'react'

import numeral from 'numeral'
import { calcEquityMultiple, IRRCalc, sumArr } from './Utils'
import { TextField } from '../../../../components/TextField'
import { useField, useForm } from 'react-final-form'
import { PromoteTabChart } from './PromoteTabChart'

type Props = {
    fcf?: number[]
    title: string
    distributions?: number[]
    contributions?: number[]
}

export const CashFlowTable = (props: Props) => {
    const { fcf, title, distributions, contributions } = props

    const fcfInternal: number[] = useMemo(() => {
        if (distributions != null && contributions != null) {
            let fcfinternal: number[] = []
            for (let i = 0; i < contributions.length; i++) {
                fcfinternal.push(distributions[i] - contributions[i])
            }
            return fcfinternal
        } else if (fcf != null) return fcf
        else return []
    }, [fcf, distributions, contributions])

    const totalEquity = useMemo(() => {
        return -fcfInternal[0]
    }, [fcfInternal])

    const equityMultiple = useMemo(() => {
        return calcEquityMultiple(fcfInternal, totalEquity)
    }, [fcfInternal])

    const IRR = useMemo(() => {
        return IRRCalc(fcfInternal, 0.001)
    }, [fcfInternal])

    const form = useForm()
    const paramName = useMemo(() => {
        if (title) return title?.replace(/ /g, '')
        else return ''
    }, [title])
    const toggleField = useField(`computed.promoteCF${paramName}`)
    const toggleRow = useCallback(() => {
        const value = toggleField.input.value || false
        form.change(`computed.promoteCF${paramName}`, !value)
    }, [toggleField])
    const isExpanded = !(toggleField.input.value || false)

    return (
        <>
            <div className="cash-flow-tab-wrapper">
                <div className={'debt-assumption-table-wrapper'}>
                    <div className="custom-table-title row">
                        <div onClick={toggleRow}>
                            <i className="material-icons-outlined">{isExpanded ? 'expand_less' : 'expand_more'}</i>
                        </div>
                        <div className="col-5 bold-900">{title}</div>
                    </div>
                    {isExpanded && (
                        <>
                            <div style={{ margin: '10px 0' }}>
                                <PromoteTabChart
                                    title={
                                        distributions != null && contributions != null
                                            ? 'Net Cash Flow'
                                            : 'Levered Cash Flow'
                                    }
                                    fcfInternal={fcfInternal}
                                />
                            </div>
                            <div className="custom-table-title row">
                                <div className="col-3"></div>
                                <div className="col-1 text-right">
                                    {distributions != null && distributions.length > 0 ? 'Total' : ''}
                                </div>
                                {fcfInternal.map((item, ind) => {
                                    return (
                                        <React.Fragment key={ind.toString()}>
                                            <div className="col-1 text-right">Year {ind}</div>
                                        </React.Fragment>
                                    )
                                })}
                            </div>
                            {distributions != null && distributions.length > 0 && (
                                <div className="custom-table-row row">
                                    <div className="col-3 ">Distributions</div>
                                    <div className="col-1 text-right">
                                        {numeral(sumArr(distributions)).format('$0,0')}
                                    </div>
                                    {distributions.map((item, ind) => {
                                        return (
                                            <React.Fragment key={ind.toString()}>
                                                <div className="col-1 text-right">{numeral(item).format('$0,0')}</div>
                                            </React.Fragment>
                                        )
                                    })}
                                </div>
                            )}

                            {contributions != null && contributions.length > 0 && (
                                <div className="custom-table-row row">
                                    <div className="col-3 ">Contributions</div>
                                    <div className="col-1 text-right">
                                        {numeral(sumArr(contributions)).format('$0,0')}
                                    </div>
                                    {contributions.map((item, ind) => {
                                        return (
                                            <React.Fragment key={ind.toString()}>
                                                <div className="col-1 text-right">{numeral(item).format('$0,0')}</div>
                                            </React.Fragment>
                                        )
                                    })}
                                </div>
                            )}

                            <div className="custom-table-row row">
                                <div className="col-3 bold-500h">
                                    {distributions != null && contributions != null ? 'Net' : 'Levered'} Cash Flow
                                </div>
                                {distributions != null && contributions != null && (
                                    <>
                                        <div className="col-1 text-right">
                                            {numeral(sumArr(fcfInternal)).format('$0,0')}
                                        </div>
                                    </>
                                )}
                                {distributions == null && contributions == null && (
                                    <>
                                        <div className="col-1 text-right"></div>
                                    </>
                                )}
                                {fcfInternal.map((item, ind) => {
                                    return (
                                        <React.Fragment key={ind.toString()}>
                                            <div className="col-1 text-right bold-500">
                                                {numeral(item).format('$0,0')}
                                            </div>
                                        </React.Fragment>
                                    )
                                })}
                            </div>

                            <div className="custom-table-row row">
                                <div className="col-3 bold-500">
                                    {distributions != null && contributions != null ? '' : 'Levered'} IRR
                                </div>
                                <div className="col-1 text-right bold-500"> {numeral(IRR).format('0.[00]%')}</div>
                            </div>
                            <div className="custom-table-row row">
                                <div className="col-3 bold-500">Equity Multiple</div>
                                <div className="col-1 text-right bold-500">
                                    {' '}
                                    {numeral(equityMultiple).format('0.[00]')}x
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>

            {/*language=scss*/}

            <style jsx>{`
                /* Pattern styles */
                @import './src/scss/colors.scss';
                .material-icons-outlined {
                    cursor: pointer;
                    float: left;
                    margin-left: 10px;
                    margin-right: -7px;
                }

                .left-half {
                    float: left;
                    width: 50%;
                    min-height: 250px;
                }

                .right-half {
                    float: left;
                    width: 50%;
                    min-height: 250px;
                }
                .add-row {
                    position: relative;
                    right: -90%;
                }
                .row {
                    margin-bottom: 10px;
                }
                .add-row-button {
                    display: flex;
                    align-items: center;
                    right: -12px;
                    top: -20px;
                    color: #fff;
                    font-size: 13px;
                    font-weight: 700;
                    text-decoration: none;
                    padding-left: 20px;
                    padding-top: 8px;
                    transform: translateX(5px);
                    transition: transform 0.25s cubic-bezier(0.165, 0.84, 0.44, 1);
                    svg {
                        height: 100%;
                        width: auto;
                        vertical-align: top;
                        transition: opacity 0.2s;
                        opacity: 0.85;
                    }
                    &:hover {
                        .add-row-label {
                            max-width: 80px;
                        }
                        svg {
                            opacity: 1;
                        }
                        .add-row-content {
                            background-color: rgba(68, 134, 255, 1);
                        }
                    }
                }
                .add-row-content {
                    background-color: rgba(68, 134, 255, 0.8);
                    padding-right: 6px;
                    border-top-right-radius: 2px;
                    border-bottom-right-radius: 2px;
                    line-height: 24px;
                }
                .add-row-label {
                    max-width: 100px;
                    overflow: hidden;
                    white-space: nowrap;
                    transition: 0.25s ease-out;
                    height: 24px;
                    padding-left: 4px;
                    font-weight: 400;
                }

                .cash-flow-tab-wrapper {
                    .custom-table-wrapper {
                        .custom-table-title {
                            text-transform: uppercase;
                            font-size: 12px;
                            color: #676f7a;
                            padding: 7px;
                        }
                        .custom-table-row {
                            font-size: 14px;
                            padding: 7px;
                            align-items: center;
                            &:nth-of-type(2n) {
                                background-color: $row-background-2;
                            }
                        }
                    }
                    .bold-500 {
                        font-weight: 500;
                    }
                    .bold-900 {
                        font-weight: 900;
                    }
                    .debt-assumption-table-wrapper {
                        border: 1px solid $border;
                        background-color: $white;
                        margin-bottom: 20px;
                        padding: 12px 20px;
                        .debt-assumption-title {
                            padding: 14px 22px;
                            font-size: 15px;
                            font-weight: 500;
                            color: $black;
                            border-bottom: 1px solid $border;
                        }
                        .custom-table-title {
                            margin-bottom: 0;
                        }
                    }
                    .widget-title {
                        padding: 12px 20px;
                        border: 1px solid $border;
                        border-bottom: 0;
                        background: $white;
                        font-size: 15px;
                        font-weight: 500;
                        color: $black;
                    }
                    .widget-tabs {
                        display: flex;
                        background-color: $white;
                        padding: 10px;
                        border-left: 1px solid $border;
                        border-right: 1px solid $border;
                        border-top: 1px solid $border;
                        .widget-tab {
                            font-size: 15px;
                            margin-right: 32px;
                            font-weight: 500;
                            cursor: pointer;
                            &.active {
                                color: $blue;
                                position: relative;
                                &:after {
                                    content: '';
                                    position: absolute;
                                    width: 100%;
                                    height: 3px;
                                    bottom: -3px;
                                    left: 0;
                                    border-top: 3px solid #4485ff;
                                    border-top-right-radius: 15px;
                                    border-top-left-radius: 15px;
                                }
                            }
                        }
                    }
                    .kpi-block {
                        display: flex;
                        background-color: $white;
                        padding: 20px 10px;
                        border-left: 1px solid $border;
                        border-right: 1px solid $border;
                        .kpi-item {
                            margin-right: 40px;
                            &__title {
                                font-weight: normal;
                                font-size: 14px;
                                color: $gray;
                                margin-bottom: 5px;
                            }
                            &__value {
                                font-weight: 500;
                                font-size: 22px;
                                color: $black;
                            }
                        }
                    }
                }
            `}</style>
        </>
    )
}
