import React from 'react'

type Props = {
    title: string
    kpi: string
}

export const Kpi = (props: Props) => {
    const { title, kpi } = props

    return (
        <>
            <div className="kpi-wrapper">
                <div className="kpi-title">{title}</div>
                <div className="kpi-value">{kpi}</div>
            </div>

            {/*language=scss*/}

            <style jsx>{`
                /* Pattern styles */
                @import './src/scss/colors.scss';
                .kpi-wrapper {
                    padding: 20px;
                    margin-right: 15px;
                    float: left;
                    width: 361px;
                    height: 87px;
                    left: 44px;
                    top: 417px;

                    background: #ffffff;
                    border: 1px solid #d9e0e5;
                    box-sizing: border-box;
                    box-shadow: 0px 4px 10px rgba(92, 99, 110, 0.0684004);
                    border-radius: 3px;
                }
                .kpi-title {
                    height: 20px;
                    left: 67px;
                    top: 434px;

                    font-family: Inter;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 18px;

                    color: #8691a2;
                }
                .kpi-value {
                    width: 62px;
                    height: 27px;
                    left: 67px;
                    top: 457px;

                    font-family: Inter;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 22px;
                    line-height: 27px;

                    color: #161c26;
                }
            `}</style>
        </>
    )
}
