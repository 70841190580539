import React, { SyntheticEvent, useCallback, useMemo } from 'react'

import numeral from 'numeral'
import { IRRCalc } from './Utils'
import { useField, useForm } from 'react-final-form'

type Props = {
    id: number
    hurdle: any
    shareholders: any
    shareholdersDist: any
    isDashboard: boolean
}

export const Hurdle = (props: Props) => {
    const { id, hurdle, shareholders, shareholdersDist, isDashboard } = props

    const form = useForm()
    const paramName = useMemo(() => {
        const x = hurdle?.title?.replace(/ /g, '')
        return x
    }, [hurdle?.title])
    const toggleField = useField(`computed.promoteCF${paramName}`)
    const toggleRow = useCallback(() => {
        const value = toggleField.input.value || false
        form.change(`computed.promoteCF${paramName}`, !value)
    }, [toggleField])
    const isExpanded = toggleField.input.value || false

    //console.log('Hurdle', id, hurdle)
    return (
        <>
            <div className="cash-flow-tab-wrapper">
                <div className={'debt-assumption-table-wrapper'}>
                    <div className="custom-table-title row">
                        <div onClick={toggleRow}>
                            <i className="material-icons-outlined">{isExpanded ? 'expand_less' : 'expand_more'}</i>
                        </div>
                        <div className="col-5 bold-900">
                            Hurdle {id} {hurdle.title != null && hurdle.title != '' ? ' - ' : ''} {hurdle.title}
                        </div>
                    </div>
                    {isExpanded && (
                        <>
                            <div className="custom-table-row row"></div>
                            <div className="custom-table-title row">
                                <div className="col-4 "></div>

                                {hurdle.arrays.beginBalanceArr[0].map((item, ind) => {
                                    return (
                                        <React.Fragment key={ind.toString()}>
                                            <div className="col-1 text-right">Year {ind}</div>
                                        </React.Fragment>
                                    )
                                })}
                            </div>

                            {shareholders.map((itemSahre, indShare) => {
                                return (
                                    <React.Fragment key={indShare.toString()}>
                                        {(id == 1 || indShare == 0) &&
                                            hurdle.irrMax != null &&
                                            (isDashboard || itemSahre.title == 'LP') && (
                                                <div className="custom-table-row row">
                                                    <div className="col-3 ">
                                                        Beginning Balance ({itemSahre.title} Capital Account)
                                                    </div>
                                                    <div className="col-1 text-right"> </div>
                                                    {hurdle.arrays.beginBalanceArr[indShare].map((item, ind) => {
                                                        return (
                                                            <React.Fragment key={ind.toString()}>
                                                                <div className="col-1 text-right">
                                                                    {numeral(item).format('$0,0')}
                                                                </div>
                                                            </React.Fragment>
                                                        )
                                                    })}
                                                </div>
                                            )}
                                        {(id == 1 || indShare == 0) &&
                                            hurdle.irrMax != null &&
                                            (isDashboard || itemSahre.title == 'LP') && (
                                                <div className="custom-table-row row">
                                                    <div className="col-3 ">
                                                        {itemSahre.title} Req'd Return to hit Hurdle 1
                                                    </div>
                                                    <div className="col-1 text-right">
                                                        {numeral(hurdle.irrMax).format('0.[00]%')}
                                                    </div>
                                                    {hurdle.arrays.reqHitReturnArr[indShare].map((item, ind) => {
                                                        return (
                                                            <React.Fragment key={ind.toString()}>
                                                                <div className="col-1 text-right">
                                                                    {numeral(item).format('$0,0')}
                                                                </div>
                                                            </React.Fragment>
                                                        )
                                                    })}
                                                </div>
                                            )}
                                        {hurdle.irrMax != null && (isDashboard || itemSahre.title == 'LP') && (
                                            <div className="custom-table-row row">
                                                <div className="col-3 bold-500">
                                                    Contributions from {itemSahre.title}
                                                </div>
                                                <div className="col-1 text-right bold-500">
                                                    {numeral(itemSahre.percentage).format('0.[00]%')}
                                                </div>
                                                {hurdle.arrays.contributionArr[indShare].map((item, ind) => {
                                                    return (
                                                        <React.Fragment key={ind.toString()}>
                                                            <div className="col-1 text-right bold-500">
                                                                {numeral(item).format('$0,0')}
                                                            </div>
                                                        </React.Fragment>
                                                    )
                                                })}
                                            </div>
                                        )}

                                        {indShare == 0 &&
                                            hurdle.irrMax != null &&
                                            hurdle.arrays.priorDistributionArr &&
                                            hurdle.arrays.priorDistributionArr.length > 0 && (
                                                <div className="custom-table-row row">
                                                    <div className="col-3 ">Prior Distributions</div>
                                                    <div className="col-1 text-right "></div>
                                                    {hurdle.arrays.priorDistributionArr[indShare].map((item, ind) => {
                                                        return (
                                                            <React.Fragment key={ind.toString()}>
                                                                <div className="col-1 text-right ">
                                                                    {numeral(item).format('$0,0')}
                                                                </div>
                                                            </React.Fragment>
                                                        )
                                                    })}
                                                </div>
                                            )}
                                        {(isDashboard || itemSahre.title == 'LP') && (
                                            <div className="custom-table-row row">
                                                <div className="col-3 bold-500">
                                                    Distributions to {itemSahre.title} (Hurdle {id})
                                                </div>
                                                <div className="col-1 text-right bold-500">
                                                    {numeral(shareholdersDist[indShare]).format('0.[00]%')}
                                                </div>
                                                {hurdle.arrays.distributionArr[indShare].map((item, ind) => {
                                                    return (
                                                        <React.Fragment key={ind.toString()}>
                                                            <div className="col-1 text-right bold-500">
                                                                {numeral(item).format('$0,0')}
                                                            </div>
                                                        </React.Fragment>
                                                    )
                                                })}
                                            </div>
                                        )}

                                        {(id == 1 || indShare == 0) &&
                                            hurdle.irrMax != null &&
                                            (isDashboard || itemSahre.title == 'LP') && (
                                                <div className="custom-table-row row">
                                                    <div className="col-3 ">
                                                        Ending Balance ({itemSahre.title} Capital Account)
                                                    </div>
                                                    <div className="col-1 text-right"></div>
                                                    {hurdle.arrays.endbalancesArr[indShare].map((item, ind) => {
                                                        return (
                                                            <React.Fragment key={ind.toString()}>
                                                                <div className="col-1 text-right">
                                                                    {numeral(item).format('$0,0')}
                                                                </div>
                                                            </React.Fragment>
                                                        )
                                                    })}
                                                </div>
                                            )}
                                        {(id == 1 || indShare == 0) &&
                                            hurdle.irrMax != null &&
                                            (isDashboard || itemSahre.title == 'LP') && (
                                                <div className="custom-table-row row">
                                                    <div className="col-2 ">{itemSahre.title} IRR Error Check</div>
                                                    <div className="col-1 text-right">
                                                        {' '}
                                                        {numeral(hurdle.irrMax).format('0.[00]%')}
                                                    </div>
                                                    <div
                                                        className="col-1 text-right"
                                                        style={{ backgroundColor: 'yellow' }}
                                                    >
                                                        {numeral(
                                                            IRRCalc(hurdle.arrays.irrErrorCheckArr[indShare], 0.001),
                                                        ).format('0.[00]%')}
                                                    </div>
                                                    {hurdle.arrays.irrErrorCheckArr[indShare].map((item, ind) => {
                                                        return (
                                                            <React.Fragment key={ind.toString()}>
                                                                <div className="col-1 text-right">
                                                                    {numeral(item).format('$0,0')}
                                                                </div>
                                                            </React.Fragment>
                                                        )
                                                    })}
                                                </div>
                                            )}
                                        <div className="custom-table-row row"></div>
                                        <div className="custom-table-row row"></div>
                                    </React.Fragment>
                                )
                            })}

                            {isDashboard && (
                                <div className="custom-table-row row">
                                    <div className="col-3 ">Total Distributions</div>
                                    <div className="col-1 text-right"> </div>
                                    {hurdle.totals.distribution.map((item, ind) => {
                                        return (
                                            <React.Fragment key={ind.toString()}>
                                                <div className="col-1 text-right">{numeral(item).format('$0,0')}</div>
                                            </React.Fragment>
                                        )
                                    })}
                                </div>
                            )}
                            {hurdle.irrMax != null && isDashboard && (
                                <div className="custom-table-row row">
                                    <div className="col-3 ">Cash Flow Remaining</div>
                                    <div className="col-1 text-right"> </div>
                                    {hurdle.totals.remainingFCF.map((item, ind) => {
                                        return (
                                            <React.Fragment key={ind.toString()}>
                                                <div className="col-1 text-right">{numeral(item).format('$0,0')}</div>
                                            </React.Fragment>
                                        )
                                    })}
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>

            {/*language=scss*/}

            <style jsx>{`
                /* Pattern styles */
                @import './src/scss/colors.scss';
                .material-icons-outlined {
                    float: left;
                    margin-left: 10px;
                    margin-right: -7px;
                    cursor: pointer;
                }
                .bold-500 {
                    font-weight: 500;
                }
                .delete-col {
                    cursor: pointer;
                }
                .left-half {
                    float: left;
                    width: 50%;
                    min-height: 250px;
                }

                .right-half {
                    float: left;
                    width: 50%;
                    min-height: 250px;
                }
                .add-row {
                    position: relative;
                    right: -90%;
                }
                .row {
                    margin-bottom: 10px;
                }
                .add-row-button {
                    display: flex;
                    align-items: center;
                    right: -12px;
                    top: -20px;
                    color: #fff;
                    font-size: 13px;
                    font-weight: 700;
                    text-decoration: none;
                    padding-left: 20px;
                    padding-top: 8px;
                    transform: translateX(5px);
                    transition: transform 0.25s cubic-bezier(0.165, 0.84, 0.44, 1);
                    svg {
                        height: 100%;
                        width: auto;
                        vertical-align: top;
                        transition: opacity 0.2s;
                        opacity: 0.85;
                    }
                    &:hover {
                        .add-row-label {
                            max-width: 80px;
                        }
                        svg {
                            opacity: 1;
                        }
                        .add-row-content {
                            background-color: rgba(68, 134, 255, 1);
                        }
                    }
                }
                .add-row-content {
                    background-color: rgba(68, 134, 255, 0.8);
                    padding-right: 6px;
                    border-top-right-radius: 2px;
                    border-bottom-right-radius: 2px;
                    line-height: 24px;
                }
                .add-row-label {
                    max-width: 100px;
                    overflow: hidden;
                    white-space: nowrap;
                    transition: 0.25s ease-out;
                    height: 24px;
                    padding-left: 4px;
                    font-weight: 400;
                }

                .cash-flow-tab-wrapper {
                    .custom-table-wrapper {
                        .custom-table-title {
                            text-transform: uppercase;
                            font-size: 12px;
                            color: #676f7a;
                            padding: 7px;
                        }
                        .custom-table-row {
                            font-size: 14px;
                            padding: 7px;
                            align-items: center;
                            &:nth-of-type(2n) {
                                background-color: $row-background-2;
                            }
                        }
                    }
                    .bold-900 {
                        font-weight: 900;
                    }
                    .debt-assumption-table-wrapper {
                        border: 1px solid $border;
                        background-color: $white;
                        margin-bottom: 20px;
                        padding: 12px 20px;
                        .debt-assumption-title {
                            padding: 14px 22px;
                            font-size: 15px;
                            font-weight: 500;
                            color: $black;
                            border-bottom: 1px solid $border;
                        }
                        .custom-table-title {
                            margin-bottom: 0;
                        }
                    }
                    .widget-title {
                        padding: 12px 20px;
                        border: 1px solid $border;
                        border-bottom: 0;
                        background: $white;
                        font-size: 15px;
                        font-weight: 500;
                        color: $black;
                    }
                    .widget-tabs {
                        display: flex;
                        background-color: $white;
                        padding: 10px;
                        border-left: 1px solid $border;
                        border-right: 1px solid $border;
                        border-top: 1px solid $border;
                        .widget-tab {
                            font-size: 15px;
                            margin-right: 32px;
                            font-weight: 500;
                            cursor: pointer;
                            &.active {
                                color: $blue;
                                position: relative;
                                &:after {
                                    content: '';
                                    position: absolute;
                                    width: 100%;
                                    height: 3px;
                                    bottom: -3px;
                                    left: 0;
                                    border-top: 3px solid #4485ff;
                                    border-top-right-radius: 15px;
                                    border-top-left-radius: 15px;
                                }
                            }
                        }
                    }
                    .kpi-block {
                        display: flex;
                        background-color: $white;
                        padding: 20px 10px;
                        border-left: 1px solid $border;
                        border-right: 1px solid $border;
                        .kpi-item {
                            margin-right: 40px;
                            &__title {
                                font-weight: normal;
                                font-size: 14px;
                                color: $gray;
                                margin-bottom: 5px;
                            }
                            &__value {
                                font-weight: 500;
                                font-size: 22px;
                                color: $black;
                            }
                        }
                    }
                }
            `}</style>
        </>
    )
}
